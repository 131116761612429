import React from 'react';

import image from './bg.jpg';

import cls from './layouts.module.scss';

interface LayoutSmallProps {
    children?: React.ReactNode
}

const LayoutSmall = ({children} : LayoutSmallProps) => (
        <div className={cls.layoutSmall} style={{backgroundImage: `url(${image})`}}>
            <div className={cls.layoutSmallContent}>
                {children}
            </div>
        </div>
    );

export default LayoutSmall