import React, {FC, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useInstance} from 'react-ioc';
import {useNavigate} from 'react-router-dom';
import {Button, Form, Input, message, Typography} from 'antd';
import {useCreateDomainMutation} from 'generated/graphql';
import LayoutSmall from 'layouts/LayoutSmall';
import {observer} from 'mobx-react-lite';
import { IFunc, ITask } from 'model/store/DomainStore';
import {Store} from 'model/store/Store';
import {INDEX} from 'routes';

interface IFormState {
    name: string,
}

const CreateDomainPage: FC = () => {

    const {domain} = useInstance(Store);
    const navigate = useNavigate();

    const {t} = useTranslation();

    const [createDomainMutation] = useCreateDomainMutation()

    const onSubmit = async (values: IFormState) => {
        await createDomainMutation({
            variables: {
                data: {
                    name: values.name
                }
            },
            onCompleted: (res: any) => {
                const id = res?.createDomain?.data.id;
                const name = res?.createDomain?.data.attributes.name;
                domain.pushDomain({
                    id: id as string,
                    name: name as string,
                    isAdmin: true,
                    taskConfig: res?.createDomain?.data.attributes.taskConfig as ITask[],
                    funcConfig: res?.createDomain?.data.attributes.funcConfig as IFunc,
                })
                navigate(INDEX);
            },
            onError: (error) => {
                message.error(error.message)
                console.error(error)
            },
        })
    }

    const [form] = Form.useForm<IFormState>();

    const initialFormState: IFormState = useMemo(() => ({
            name: '',
        }), []);

    return (
        <LayoutSmall>

            <Typography.Title level={3}>{t('Create new Workspace')}</Typography.Title>

            <Form name={'domain'}
                layout='vertical'
                  initialValues={initialFormState}
                  form={form}
                  onFinish={onSubmit}
                  scrollToFirstError
                  size={'large'}
            >

                <Form.Item name='name' label={t('Workspace name')}
                           rules={[
                               {required: true, message: `${t('Required')}`},
                           ]}

                >
                    <Input/>
                </Form.Item>

                <Form.Item>
                    <Button block type='primary' htmlType="submit">{t('Create')}</Button>
                </Form.Item>

            </Form>

        </LayoutSmall>
    );
};

export default observer(CreateDomainPage);



