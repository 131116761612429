import { ITag } from './Board';

import cls from './Tag.module.scss'

interface ITagProps {
  data: ITag
}

export const Tag = ({data}:ITagProps) => (
  <span className={cls.tag} style={{backgroundColor: data.color || '#651F71'}}>
    {data.name}
  </span>
)