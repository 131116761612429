import { useTranslation } from 'react-i18next'
import img from 'assets/logo/AppIcon.svg'

import cls from './logo.module.scss'

export const LogoSlogan  = () => {

  const {t} = useTranslation();

  return (
    <div className={cls.LogoSlogan}>
      <div className={cls.Logo}><img src={img} alt={'Saturn Logo'}/></div>
      <div className={cls.Slogan}>
        <div className={cls.Title}>{t('Saturn')}</div>
        <div>{t('App for digital creators')}</div>
      </div>
    </div>
  )
}