import React, {FC, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useInstance} from 'react-ioc';
import {useNavigate} from 'react-router-dom';
import {Button, Form, Input, message, Typography} from 'antd';
import {useCheckEmailLazyQuery, useCheckUsernameLazyQuery, useRegisterMutation} from 'generated/graphql';
import LayoutSmall from 'layouts/LayoutSmall';
import {observer} from 'mobx-react-lite';
import {Store} from 'model/store/Store';
import {INDEX} from 'routes';

interface IFormState {
    username: string,
    email: string,
    password: string,
    passwordConfirm: string,
}

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const RegisterPage: FC = () => {

    const store = useInstance(Store);
    const navigate = useNavigate();

    const {t} = useTranslation();

    const [registerMutation] = useRegisterMutation()
    const [checkEmail] = useCheckEmailLazyQuery()
    const [checkUsername] = useCheckUsernameLazyQuery()

    const onSubmit = async (values: IFormState) => {
        await registerMutation({
            variables: {
                input: {
                    username: values.username,
                    email: values.email,
                    password: values.password
                }
            },
            onCompleted: (data: any) => {
                const jwt = data?.register.jwt;
                const userId = data?.register.user.id;
                store.auth.setTokenAndId(jwt, userId);
                store.domain.clearState();
                navigate(INDEX);

            },
            onError: (error) => {
                message.error(error.message)
            },
        })
    }

    // const onSubmit = async () => {
    //   const values = getValues();
    //   try {
    //     const res = await loginMutation({
    //       variables: {
    //         input: {
    //           identifier: values.login,
    //           password: values.password,
    //         },
    //       },
    //     });
    //     const jwt = res.data?.login.jwt;
    //     const userId = res.data?.login.user.id;
    //     if (!jwt || !userId) {
    //       message.error('Ошибка при авторизации');
    //       return;
    //     }
    //
    //     store.auth.setTokenAndId(jwt, userId);
    //
    //     navigate(INDEX);
    //   } catch (error: any) {
    //     console.error(error);
    //     if (error.message === 'Failed to fetch') {
    //       message.error('Ошибка получения данных');
    //     } else {
    //       message.error('Логин или пароль не верный');
    //     }
    //   }
    // };

    const [form] = Form.useForm<IFormState>();

    const initialFormState: IFormState = useMemo(() => ({
            username: '',
            email: '',
            password: '',
            passwordConfirm: '',
        }), []);

    const emailValidate = async (rule:any, value:any) => {
        const queryResult = await checkEmail({
            variables: {email: value},
        })
        const valid =  queryResult.data?.checkEmail?.valid
        if (valid) {
            return Promise.resolve()
        } else {
            return Promise.reject(t('Email taken'))
        }
    };

    const usernameValidate = async (rule:any, value:any) => {
        const queryResult = await checkUsername({
            variables: {username: value},
        })
        const valid =  queryResult.data?.checkUsername?.valid
        if (valid) {
            return Promise.resolve()
        } else {
            return Promise.reject(t('Username taken'))
        }
    };

    return (
        <LayoutSmall>

            <Typography.Title level={3}>{t('Register new user')}</Typography.Title>

            <Form name={'register'}
                layout='vertical'
                  initialValues={initialFormState}
                  form={form}
                  onFinish={onSubmit}
                  scrollToFirstError
                  autoComplete={'off'}
            >

                <Form.Item name='username' label={t('Username')}
                           hasFeedback
                           validateFirst={true}
                           rules={[
                               {required: true, message: `${t('Required')}`},
                               {validator: usernameValidate},
                           ]}

                >
                    <Input autoComplete={'none'}/>
                </Form.Item>

                <Form.Item name='email' label={t('Email')}
                           hasFeedback
                           validateFirst={true}
                           rules={[
                               {required: true, message: `${t('Required')}`},
                               {pattern: emailRegex, message: `${t('Invalid format')}`},
                               {validator: emailValidate},
                           ]}
                >
                    <Input placeholder="example@site.com" autoComplete={'none'}/>
                </Form.Item>

                <Form.Item name='password' label={t('Password')}
                           hasFeedback
                           rules={[
                    {required: true, message: `${t('Required')}`},
                    {min: 6, message: `${t('Password must be at least 6 characters')}`},
                ]}>
                    <Input.Password autoComplete={'none'}/>
                </Form.Item>

                <Form.Item name='confirmPassword' label={t('Confirm password')}
                           hasFeedback
                           dependencies={['password']}
                           rules={[
                               {required: true, message: `${t('Required')}`},
                               ({ getFieldValue }) => ({
                                   validator(_, value) {
                                       if (!value || getFieldValue('password') === value) {
                                           return Promise.resolve();
                                       }
                                       return Promise.reject(new Error('The two passwords that you entered do not match'));
                                   },
                               }),
                           ]}>
                    <Input.Password/>
                </Form.Item>

                <Form.Item>
                    <Button block type='primary' htmlType="submit">{t('Register')}</Button>
                </Form.Item>

            </Form>

        </LayoutSmall>
    );
};

export default observer(RegisterPage);



