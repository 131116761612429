import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useInstance} from 'react-ioc';
import { useNavigate} from 'react-router-dom';
import {Button, Form, Input, message} from 'antd';
import {useLoginMutation} from 'generated/graphql';
import LayoutSmall from 'layouts/LayoutSmall';
import {observer} from 'mobx-react-lite';
import {Store} from 'model/store/Store';
import { INDEX} from 'routes';

import { LogoSlogan } from 'components/LogoSlogan/LogoSlogan';

import cls from './Login.module.scss';

interface IFormState {
    identifier: string,
    password: string,
}

const LoginPage: FC = () => {

    const [loginMutation] = useLoginMutation();

    const store = useInstance(Store);
    const navigate = useNavigate();

    const [form] = Form.useForm<IFormState>();

    const {t} = useTranslation();

    const onSubmit = async (values: IFormState) => {
        await loginMutation({
            variables: {
                input: {
                    identifier: values.identifier,
                    password: values.password,
                }
            },
            onCompleted: (data: any) => {
                const jwt = data?.login.jwt;
                const userId = data?.login.user.id;
                store.auth.setTokenAndId(jwt, userId);
                store.domain.clearState();
                navigate(INDEX);
            },
            onError: (error) => {
                message.error(error.message)
            },
        })
    }

    return (
        <LayoutSmall>

            <LogoSlogan/>

            <div className={cls.form}>
                <Form layout='vertical'
                      form={form}
                      onFinish={onSubmit}
                      scrollToFirstError
                >

                    <Form.Item name='identifier' label={t('Email')}
                               rules={[
                                   {required: true, message: `${t('Required')}`},
                                   {type: 'email', message: `${t('Invalid format')}`},
                               ]}
                    >
                        <Input placeholder="example@site.com"/>
                    </Form.Item>

                    <Form.Item name='password' label={t('Password')}
                               rules={[
                                   {required: true, message: `${t('Required')}`},
                               ]}
                    >
                        <Input.Password/>
                    </Form.Item>

                    <Form.Item>
                        <Button block htmlType={'submit'} type='primary'>{t('Login')}</Button>
                    </Form.Item>

                </Form>

            </div>

        </LayoutSmall>
    );
};

export default observer(LoginPage);



