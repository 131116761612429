import _ from 'lodash';
import {
  applySnapshot,
  types as t
} from 'mobx-state-tree';

export const initialDomainStore = {
  domains: [],
  selectedDomain: null,
  loaded: false
};

export interface IDomain {
  id: string;
  name: string;
  isAdmin: boolean;
  task: ITask[];
  func: IFunc;
}

export interface ITask {
  name: string;
  visible: boolean;
  color?: string;
  bgColor?: string;
  focus?: boolean;
}

export interface IFunc {
  focus?: boolean;
}

export const DomainStore = t
  .model({
    domains: t.array(
      t.model({
        id: t.string,
        name: t.string,
        isAdmin: t.boolean,
        taskConfig: t.array(
          t.model({
            name: t.string,
            visible: t.boolean,
            color: t.maybeNull(t.string),
            bgColor: t.maybeNull(t.string),
            focus: t.maybeNull(t.boolean)
          })
        ),
        funcConfig: t.model({
          focus: t.maybeNull(t.boolean)
        })
      })
    ),
    selectedDomain: t.maybeNull(t.string),
    loaded: t.boolean
  })
  .views((self) => ({
    currentDomain: () => _.find(self.domains, { id: self.selectedDomain }),
    hasFocus: () => {
      const current:any = _.find(self.domains, { id: self.selectedDomain })
      return current.funcConfig.focus || false
    },
  }))
  .actions((self) => ({
    clearState: () => {
      applySnapshot(self, initialDomainStore);
    },
    setDomains: (data: any | null) => {
      self.domains = data;
      self.loaded = true;
    },
    pushDomain: (domain: { id: string, name: string, isAdmin: boolean, taskConfig: ITask[], funcConfig: IFunc }) => {
      self.domains.push(domain);
      self.selectedDomain = domain.id;
    },
    selectDomain: (id: string) => {
      self.selectedDomain = id;
    }
  }));
