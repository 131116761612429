import React, {FC, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useInstance} from 'react-ioc';
import {useNavigate} from 'react-router-dom';
import {Button, Form, Input, message, Typography} from 'antd';
import {useCreateInviteMutation} from 'generated/graphql';
import LayoutSmall from 'layouts/LayoutSmall';
import {observer} from 'mobx-react-lite';
import {Store} from 'model/store/Store';
import {INDEX} from 'routes';

interface IFormState {
    email: string,
}

const CreateInvitePage: FC = () => {

    const {domain} = useInstance(Store);
    const navigate = useNavigate();

    const {t} = useTranslation();

    const [createInviteMutation] = useCreateInviteMutation()

    const onSubmit = async (values: IFormState) => {
        await createInviteMutation({
            variables: {
                data: {
                    email: values.email,
                    domain: domain.selectedDomain || ''
                }
            },
            onCompleted: () => {
                navigate(INDEX);
            },
            onError: (error) => {
                message.error(error.message)
                console.error(error)
            },
        })
    }

    const [form] = Form.useForm<IFormState>();

    const initialFormState: IFormState = useMemo(() => ({
            email: '',
        }), []);

    return (
        <LayoutSmall>

            <Typography.Title level={3}>{t('Invite user to my Workspace')}</Typography.Title>

            <Form name={'invite'}
                layout='vertical'
                  initialValues={initialFormState}
                  form={form}
                  onFinish={onSubmit}
                  scrollToFirstError
            >

                <Form.Item name='email' label={t('Email')}
                           rules={[
                               {required: true, message: `${t('Required')}`},
                           ]}

                >
                    <Input/>
                </Form.Item>

                <Form.Item>
                    <Button block type='primary' htmlType="submit">{t('Invite')}</Button>
                </Form.Item>

            </Form>

        </LayoutSmall>
    );
};

export default observer(CreateInvitePage);



